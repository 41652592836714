jQuery(document).ready(function () {
    jQuery('.col-twins').matchHeight({
        byRow: true,
        property: 'height',
        target: null,
        remove: false
    });
    jQuery('.col-menu').matchHeight({
        byRow: true,
        property: 'height',
        target: jQuery("#desktop_header .logo_wrapper"),
        remove: false
    });
    jQuery('.col-footer').matchHeight({
        byRow: true,
        property: 'height',
        target: null,
        remove: false
    });

    jQuery('.col_post_content').matchHeight({
        byRow: true,
        property: 'height',
        target: null,
        remove: false
    });

    jQuery('.col_post_content').matchHeight({
        byRow: true,
        property: 'height',
        target: null,
        remove: false
    });

    jQuery('.col_post_title').matchHeight({
        byRow: false,
        property: 'height',
        target: null,
        remove: false
    });

    jQuery('.col_post_quick').matchHeight({
        byRow: false,
        property: 'height',
        target: null,
        remove: false
    });


    TweenLite.to(jQuery(".search-box"), 0, {ease: Power4.easeInOut, y:100, opacity: 0});
    // SEARCH FORM TOGGLE
    jQuery('.search-close').click(function() {
        TweenLite.to(jQuery(".search-box"), 0.5, {ease: Power4.easeInOut, y:100, opacity: 0});
        TweenLite.to(jQuery(".main_menu_wrapper"), 0.5, {ease: Power4.easeInOut, y:0, opacity: 1});
        jQuery( ".search-box" ).removeClass('active');
    });

    jQuery('#search-icon').click(function(e) {
        e.preventDefault();
        TweenLite.to(jQuery(".main_menu_wrapper"), 0.5, {ease: Power4.easeInOut, y:-100, opacity: 0});
        TweenLite.to(jQuery(".search-box"), 0.5, {ease: Power4.easeInOut, y:0, opacity: 1});
        jQuery( ".search-box" ).addClass('active');
        jQuery( ".search-box input" ).focus();
    });


    jQuery('#search-icon-mobile').click(function(e) {
        e.preventDefault();
        TweenLite.to(jQuery(".search-box"), 0.5, {ease: Power4.easeInOut, y:0, opacity: 1});
        jQuery( ".search-box" ).addClass('active');
        jQuery( ".search-box input" ).focus();
    });

    // ANCHOR SCROLLS
    function scrollToAnchor(hash) {
        jQuery('html,body').animate({scrollTop: jQuery(hash).offset().top},'slow');
    }

    // Scroll to section
    jQuery('.section_down').click(function(e) {
        e.preventDefault();
        var hash = jQuery(this).attr("href");
        scrollToAnchor(hash);
    });

    // Scroll to section
    jQuery('.arrow-up').click(function(e) {
        e.preventDefault();
        jQuery('html,body').animate({scrollTop: 0},'slow');
    });


    // mobile menu scrolling
    jQuery('#menu-wrapper-mobile').slimScroll({
        height: 'calc(100vh - 40px)',
        alwaysVisible: false
    });

    jQuery('#mobile-menu .menu-item-has-children > a').click(function(e) {
        if(jQuery(this).siblings('.sub-menu').hasClass('active')) {
            // do nothing
        } else {
            e.preventDefault();
            jQuery(this).siblings('.sub-menu').addClass('active');
            TweenLite.set(jQuery(this).siblings('.sub-menu'), {ease: Power4.easeInOut, height:"auto"});
        }
    });

    // mobile menu toggle
    jQuery("#hamburger-icon, #hamburger-icon-close, #search-icon-mobile").click(function() {

        var $hamburger = jQuery("#hamburger-icon"),
            $hamburgerClose = jQuery("#hamburger-icon-close"),
            $mobilemenu = jQuery("#mobile-navigation");
            
        $hamburgerClose.toggleClass('open');
        jQuery('body').toggleClass('menu-open');
        // $hamburger.toggleClass('is-active');
        if(!$hamburger.hasClass("closed")) {

            jQuery(jQuery("#mobile-menu > li").get().reverse()).each(function(i) { 
                TweenLite.to(jQuery(this), 0.2 * i, { ease: Power4.easeInOut, opacity:0});
            });


            TweenLite.to($mobilemenu, 0.5, {ease: Power4.easeInOut, height:0});
            $mobilemenu.removeClass("open");
            $hamburger.addClass("closed");

        } else {

            jQuery("#mobile-menu > li").each(function(i) {
                TweenLite.to(jQuery(this), 0.2 * i, {ease: Power4.easeInOut, opacity:1});
            });

            TweenLite.set($mobilemenu, {ease: Power4.easeInOut, height:"auto"});
            jQuery("#mobile-menu").css("maxHeight", jQuery("#mobile-menu").height());
            var menu_height = jQuery('#mobile-navigation').height();
            
            TweenLite.from($mobilemenu, 0.5, {ease: Power4.easeInOut, height:0});
            $hamburger.removeClass("closed");
            $mobilemenu.addClass("open");
            
            TweenLite.to(jQuery(".search-box"), 0.5, {ease: Power4.easeInOut, y:100, opacity: 0});
            jQuery( ".search-box" ).removeClass('active');
        }

    });

    // Header links smoothscroll
    jQuery('a[href*="#"]').click(function(e) {
        var url = jQuery(this).attr("href");
        var hash = url.substring(url.indexOf('#')+1);
        var item = jQuery('#'+hash);
        if (item.length) {
            e.preventDefault();
            scrollToAnchor('#'+hash);
        }
    });

    var hInterval;
    var hTimeout;
    var hTimeout2;
    var lastElement;
    jQuery("#desktop_menu > li").hover(
        function(e) {
            $this = jQuery(this);
            if(e.target != lastElement) {
                hTimeout = setTimeout(function() {
                    jQuery("#desktop_menu li").not(this).find('.sub-menu').removeClass('active');
                    $this.find('.sub-menu').addClass('active');
                }, 500);
                lastElement = e.target;
            } else {
                clearTimeout(hTimeout);
            }
            return false;
        }, function() {
            $this = jQuery(this);
        }
    );

    jQuery("#desktop_menu").hover(function() {
        hTimeout2 = setTimeout(function() {
            jQuery("#desktop_menu > li .sub-menu").removeClass('active');
            lastElement = "";
        }, 1000);
    });
});